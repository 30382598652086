
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { VxeFormInstance, VxeFormProps, VxeFormEvents } from 'vxe-table'
import request from '@/plugin/request';
export default defineComponent({
    components: {

    },
    emits: ["handleQuery"],
    setup (props, context) {

        const modal = reactive({
            visible: false,
        })

        const departureForm = ref({} as VxeFormInstance)
        const departureFormMethod = reactive({
            submit: () => {
                // context.emit('handleQuery', searchFormOptions.data)
                console.log('点击了提交')

                request('/roster/employee/departure', departureFormOptions.data, { method: 'POST' }).then(response => {

                    context.emit('handleQuery')
                    modal.visible = false
                }).finally(() => { })
            },
        })
        const departureFormOptions = reactive<VxeFormProps>({
            data: {
                id: undefined,
                name: undefined,
                date: undefined,
                reason: undefined,
            },
            items: [
                { field: 'name', title: '员工', span: 12 },
                { field: 'date', title: '离职日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '请选择离职日期' } } },
                { field: 'reason', title: '原因', span: 24, itemRender: { name: '$textarea', props: { placeholder: '请填离职原因', autosize: { minRows: 5 } } } },
                {
                    span: 24,
                    align: 'center',
                    itemRender: {
                        name: '$button',
                        props: { status: 'primary', content: '提交' }, events: { click: departureFormMethod.submit }
                    }
                }
            ],
            rules: {

            }
        })

        nextTick(() => {

        })

        onMounted(() => {

        })

        return {
            modal,
            departureForm,
            departureFormMethod,
            departureFormOptions
        };

    },

});
