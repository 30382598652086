
import { defineComponent, ref, reactive, nextTick, onMounted } from 'vue';
import { useRoute, useRouter } from "vue-router"
import { VXETable } from 'vxe-table';
import { VxeFormInstance, VxeFormProps, VxeFormEvents } from 'vxe-table'
import request from '@/plugin/request';
import { sex, type, status, marriage, political, residence, corp_probation_period } from './options'
export default defineComponent({
  components: {

  },
  setup () {

    const router = useRouter();
    const route = useRoute();

    const goBack = () => {
      router.go(-1)
    }

    const selectOptions = reactive({
      department: [] as any[]
    })


    const infoForm = ref({} as VxeFormInstance)
    const infoFormMethod = reactive({
      submit: () => {

        const $form = infoForm.value
        $form.validate().then(() => {

          infoFormOptions.loading = true

          request('/roster/employee', infoFormOptions.data, { method: 'POST' }).then(response => {
            goBack()
          }).finally(() => { infoFormOptions.loading = false })

        })


      },
      upload: async (type: string) => {
        const { file } = await VXETable.readFile()
        let data = new FormData(); // 创建form对象
        data.append("file", file); // 通过append向form对象添加数据
        request('/file/upload', data, { method: 'POST', upload: true, module: '/' }).then(response => {
          infoFormOptions.data[type] = process.env.VUE_APP_BASE_API + response.result.url
        }).finally(() => { })
      }

    })
    const infoFormOptions = reactive<VxeFormProps>({
      loading: false,
      data: {
        id: undefined,
        mobile: undefined,
        name: undefined,

        code: undefined,
        position: undefined,
        type: undefined,
        status: undefined,

        sex: undefined,
        birthday: undefined,

        marriage: undefined,
        political: undefined,
        residence: undefined,
        address: undefined,

        corp_entry_date: undefined,
        corp_probation_period: undefined,
        corp_regular_time: undefined,

        idcard_no: undefined,
        idcard_name: undefined,
        idcard_sex: undefined,
        idcard_birthday: undefined,
        idcard_nation: undefined,
        idcard_expiry: undefined,
        idcard_address: undefined,

        personal_social_security_no: undefined,
        personal_accumulation_fund_no: undefined,

        bank_account_no: undefined,
        bank_open_bank: undefined,

        edu_qualifications: undefined,
        edu_major: undefined,
        edu_graduate_school: undefined,
        edu_graduation_time: undefined,

        contacts_name: undefined,
        contacts_relation: undefined,
        contacts_phone: undefined,

        family_name: undefined,
        family_phone: undefined,
        family_relation: undefined,
        family_sex: undefined,
        family_birthday: undefined,

        materials_idcard_front: undefined,
        materials_idcard_rear: undefined,
        materials_academic_certificate: undefined,
        materials_diploma_certificate: undefined,
        materials_release_letter: undefined,
        materials_photo: undefined,

      },
      items: [
        { span: 24, slots: { default: 'base' } },
        { field: 'id', title: '编码', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写员工编码' } } },
        { field: 'mobile', title: '手机', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写手机号' } } },
        { field: 'name', title: '姓名', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写姓名' } } },
        { field: 'code', title: '工号', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写工号' } } },
        { field: 'position', title: '职务', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写职务' } } },
        { field: 'type', title: '类型', span: 12, itemRender: { name: '$select', options: type, props: { placeholder: '请选择员工类型' } } },
        { field: 'status', title: '状态', span: 12, itemRender: { name: '$select', options: status, props: { placeholder: '请选择员工状态' } } },
        { field: 'sex', title: '性别', span: 12, itemRender: { name: '$select', options: sex, props: { placeholder: '请选择性别' } } },
        { field: 'birthday', title: '生日', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '请选择生日' } } },
        { field: 'marriage', title: '婚姻状况', span: 12, itemRender: { name: '$select', options: marriage, props: { placeholder: '请选择婚姻状况' } } },
        { field: 'political', title: '政治面貌', span: 12, itemRender: { name: '$select', options: political, props: { placeholder: '请选择政治面貌' } } },
        { field: 'residence', title: '户籍', span: 12, itemRender: { name: '$select', options: residence, props: { placeholder: '请选择户籍类型' } } },
        { field: 'address', title: '住址', span: 24, itemRender: { name: '$input', props: { placeholder: '请填写现住址' } } },

        { span: 24, slots: { default: 'work' } },

        { title: '部门', span: 12, slots: { default: 'department_id' } },

        // { field: 'department_id', title: '部门', span: 12, itemRender: { name: '$select', options: selectOptions.department, props: { placeholder: '请选择户籍类型' }  } },
        { field: 'corp_entry_date', title: '入职日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '请选择入职日期' } } },
        { field: 'corp_probation_period', title: '试用期限', span: 12, itemRender: { name: '$select', options: corp_probation_period, props: { placeholder: '请选择试用期' } } },
        { field: 'corp_regular_time', title: '转正日期', span: 12, itemRender: { name: '$input', props: { type: 'date', placeholder: '请选择转正日期' } } },

        { span: 24, slots: { default: 'account' } },
        { field: 'idcard_no', title: '身份证号码', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写身份证号码' } } },
        { field: 'idcard_name', title: '身份证姓名', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写身份证姓名' } } },

        { field: 'idcard_sex', title: '身份证性别', span: 12, itemRender: { name: '$input', props: { placeholder: '请选择身份证性别' } } },
        { field: 'idcard_birthday', title: '身份证生日', span: 12, itemRender: { name: '$input', props: { placeholder: '请选择身份证生日' } } },
        { field: 'idcard_nation', title: '身份证民族', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写身份证民族' } } },
        { field: 'idcard_expiry', title: '身份证有效期', span: 12, itemRender: { name: '$input', props: { placeholder: '请填写身份证有效期' } } },

        { field: 'idcard_address', title: '身份证地址', span: 24, itemRender: { name: '$input', props: { placeholder: '请填写身份证地址' } } },

        { field: 'personal_social_security_no', title: '个人社保账号', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'个人社保账号'}` } } },

        { field: 'personal_accumulation_fund_no', title: '个人公积金账号', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'个人公积金账号'}` } } },


        { field: 'bank_account_no', title: '银行卡号', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'银行卡号'}` } } },

        { field: 'bank_open_bank', title: '开户行', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'开户行'}` } } },

        { span: 24, slots: { default: 'edu' } },

        { field: 'edu_qualifications', title: '学历', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'学历'}` } } },
        { field: 'edu_major', title: '专业', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'专业'}` } } },
        { field: 'edu_graduate_school', title: '毕业院校', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'毕业院校'}` } } },
        { field: 'edu_graduation_time', title: '毕业时间', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'毕业时间'}` } } },


        { span: 24, slots: { default: 'family' } },

        { field: 'contacts_name', title: '紧急联系人姓名', span: 8, itemRender: { name: '$input', props: { placeholder: `请填写${'紧急联系人姓名'}` } } },
        { field: 'contacts_relation', title: '紧急联系人关系', span: 8, itemRender: { name: '$input', props: { placeholder: `请填写${'紧急联系人关系'}` } } },
        { field: 'contacts_phone', title: '紧急联系人电话', span: 8, itemRender: { name: '$input', props: { placeholder: `请填写${'紧急联系人电话'}` } } },

        { field: 'family_name', title: '家人姓名', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'家人姓名'}` } } },
        { field: 'family_phone', title: '家人电话', span: 12, itemRender: { name: '$input', props: { placeholder: `请填写${'家人电话'}` } } },
        { field: 'family_relation', title: '家人关系', span: 8, itemRender: { name: '$input', props: { placeholder: `请填写${'毕业家人关系间'}` } } },
        { field: 'family_sex', title: '家人性别', span: 8, itemRender: { name: '$input', props: { placeholder: `请填写${'家人性别'}` } } },
        { field: 'family_birthday', title: '家人生日', span: 8, itemRender: { name: '$input', props: { placeholder: `请填写${'家人生日'}` } } },

        { span: 24, slots: { default: 'materials' } },

        { field: 'materials_idcard_front', title: '身份证（人像面）', span: 12, slots: { default: 'materialsIdcardFront' } },
        { field: 'materials_idcard_rear', title: '身份证（国徽面）', span: 12, slots: { default: 'materialsIdcardRear' } },

        // { field: 'materials_academic_certificate', title: '学历证书', span: 12, slots: { default: 'materialsAcademicCertificate' } },
        // { field: 'materials_diploma_certificate', title: '学位证据', span: 12, slots: { default: 'materialsDiplomaCertificate' } },

        { field: 'materials_release_letter', title: '原单位解除劳动合同声明', span: 12, slots: { default: 'materialsReleaseLetter' } },
        { field: 'materials_photo', title: '员工照片', span: 12, slots: { default: 'materialsPhoto' } },
           { field: 'contract_photo', title: '合同照片', span: 24, slots: { default: 'contractPhoto' } },

      ],
      rules: {
        mobile: [{ required: true, message: '手机是必填项' }],
        name: [{ required: true, message: '姓名是必填项' }],
        type: [{ required: true, message: '员工类型是必填项' }],
        status: [{ required: true, message: '员工状态是必填项' }],
        corp_entry_date: [{ required: true, message: '入职日期是必填项' }],
        position: [{ required: true, message: '职务是必填项' }]
      }
    })



    onMounted(() => {

      request(`/roster/employee/dict/department`, {}).then(response => {

        selectOptions.department = response.result.list

        if (route.query.id) {
          infoFormOptions.loading = true

          request('/roster/employee/show', { id: route.query.id }, {}).then(response => {
            const { result } = response
            if (result) {
              infoFormOptions.data = result
            }
          }).finally(() => { infoFormOptions.loading = false })


        }
      })

    })



    return {
      goBack,
      infoForm,
      infoFormMethod,
      infoFormOptions,
      selectOptions
    };

  },

});
